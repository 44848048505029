import Login from "./components/Login";
import ModalPage from "./components/Modal";
import Sessions from "./components/Sessions";
import Trader from "./components/Trader";
import ModalWrapper from "./context/wrapper/modalWrapper";
import SignWrapper from "./context/wrapper/SignWrapper";



function App() {
  return (
    <ModalWrapper>
      <SignWrapper>
        <div className="relative max-h-screen bg-gray-700 p-5">
          <div className="gird grid-flow-row h-full bg-white items-center p-3 w-full">
            <p className="text-3xl text-center">Botx Client</p>
            <p className="text-xl text-center">Easy Money Maker</p>
            <Trader />
            <p className="text-xl text-center pt-5 pb-5">Open Sessions</p>
            <Sessions />
          </div>
          <Login />
          <ModalPage />
        </div>
      </SignWrapper>
    </ModalWrapper>
  );
}

export default App;
