/* eslint-disable no-void */
/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext } from 'react';


const SignContext = createContext({
  visible: false,
  type: 'signIn',
  setVisible: () => void 0,
  setType: () => void 0,
});

export default SignContext;
