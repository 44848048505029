import { Button, Input } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import useFetch from '../hooks/useFetch'

const UpdateTrader = ({ visible = false, items = {}, setVisible }) => {
    const { startFetch, response } = useFetch()

    const formik = useFormik({
        initialValues: {
            market: items.market,
            gridStage: items.gridStage,
            amount: items.amount,
            afterComa: items.afterComa
        },
        onSubmit: (values) => {
            startFetch({
                url: '/updateSession', method: 'POST', payload: {
                    id: items.id,
                    ...values
                }
            })
        }
    })
    useEffect(() => {
        if (response.ok) {
            setVisible(false)
        }
    }, [response])

    useEffect(() => {
        formik.setValues({
            market: items.market,
            gridStage: items.gridStage,
            amount: items.amount,
            afterComa: items.afterComa
        })
    }, [items])

    return (

        visible && (
            <div className="grid grid-cols-2 auto-cols-auto gap-2 pt-5">
                <Input disabled value={formik.values.market} onChange={formik.handleChange('market')} className="rounded-full" placeholder="Merket" />
                <Input value={formik.values.gridStage} onChange={formik.handleChange('gridStage')} className="rounded-full" placeholder="Grid Stage" type="number" />
                <Input value={formik.values.amount} onChange={formik.handleChange('amount')} className="rounded-full" placeholder="Amount" type="number" />
                <Input value={formik.values.afterComa} onChange={formik.handleChange('afterComa')} className="rounded-full" placeholder="After Coma" type="number" />
                <Button onClick={formik.submitForm} className="col-span-2 bg-gray-700 text-white rounded-full" title="Create A Session">Update the Session</Button>
            </div>
        )

    )
}

export default UpdateTrader