import { Button, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import useFetch from '../hooks/useFetch'
import UpdateTrader from './UpdateTrader'

const Sessions = () => {
    const { startFetch, response } = useFetch()
    const [sessions, setSessions] = useState([])
    const [updateVisible, setUpdateVisible] = useState(false)
    const [selectedItem, setSelectedItem] = useState()
    useEffect(() => {
        startFetch({ url: '/listSessions', method: "GET" })
    }, [])

    useEffect(() => {
        if (response?.ok) {
            setSessions(response?.data)
            localStorage.setItem('sessions', JSON.stringify(response?.data))
        }
    }, [response])
    return (
        <>
            <Table scroll={{ x: 600 }} rowKey={k => k} dataSource={sessions} columns={[{
                dataIndex: 'market',
                fixed: 'right',
                width: 100,
                key: 'market',
                title: 'Market',
            },
            {
                dataIndex: 'totalBuy',
                key: 'buys',
                title: 'Buys',

            },
            {
                dataIndex: 'totalSell',
                key: 'sels',
                title: 'Sells',
            },
            {
                dataIndex: 'totalPnL',
                key: 'pnl',
                title: 'PnL',
            },
            {
                dataIndex: 'amount',
                key: 'amount',
                title: 'Amount', ellipsis: true,
            },
            {
                title: 'Action',
                key: 'action',
                render: (_, record) => {
                    return <div className='grid grid-flow-col gap-3'>
                        <Button onClick={() => {
                            startFetch({
                                url: '/stopSession', method: 'POST', payload: {
                                    id: record.id
                                }
                            })
                        }}>Stop</Button>
                        <Button onClick={() => {
                            setUpdateVisible(true)
                            setSelectedItem(record)
                        }}>Update</Button>

                    </div>
                }
            }
            ]} />
            {updateVisible && <UpdateTrader setVisible={setUpdateVisible} visible={updateVisible} items={selectedItem} />}
        </>
    )
}

export default Sessions