import { Button, Modal } from 'antd'
import React, { useContext } from 'react'
import ModalContext from '../context/modaContext'

const ModalPage = () => {
    const { visible, setVisible, message } = useContext(ModalContext)

    return (
        <Modal visible={visible} footer={false}>
            <div className="grid grid-flow-row auto-rows-auto gap-2">
                <p className="text-3xl text-center">Error</p>
                <p className="text-xl text-center">{message}</p>
                <Button onClick={() => {
                    setVisible(false)
                }} className="bg-gray-700 text-white rounded-full" title="Sign In">Okay</Button>
            </div>

        </Modal>
    )
}

export default ModalPage