import { Button, Input } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import useFetch from '../hooks/useFetch'
import UserCard from './UserCard'
import useAuth from '../hooks/useAuth'
const Trader = () => {
    const { startFetch, response } = useFetch()
    const { user } = useAuth()
    const formik = useFormik({
        initialValues: {
            market: '',
            gridStage: 0,
            amount: 0,
            afterComa: 0,
            long: true
        },
        onSubmit: (values) => {
            startFetch({
                url: '/runBot', method: 'POST', payload: {
                    ...values,
                    dynamicStage: values.gridStage,
                }
            })
        }
    })
    useEffect(() => {
        if (response?.ok) {

        }
    }, [response])
    return (
        <>
            <UserCard user={user} />
            <div className="grid grid-cols-2 auto-cols-auto gap-2 pt-5">
                <Input onChange={formik.handleChange('market')} className="rounded-full" placeholder="Merket" />
                <Input onChange={formik.handleChange('gridStage')} className="rounded-full" placeholder="Grid Stage" type="number" />
                <Input onChange={formik.handleChange('amount')} className="rounded-full" placeholder="Amount" type="number" />
                <Input onChange={formik.handleChange('afterComa')} className="rounded-full" placeholder="After Coma" type="number" />
                <select defaultValue={true} onChange={(e) => {
                    e.preventDefault()
                    formik.setFieldValue('long', e.target.value === '1')
                }}>
                    <option value={1}>Long</option>
                    <option value={0}>Sort</option>
                </select>
                <Button onClick={formik.submitForm} className="col-span-2 bg-gray-700 text-white rounded-full" title="Create A Session">Create a Session</Button>
            </div>
        </>
    )
}

export default Trader