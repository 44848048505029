export const isEmptyObject = (obj) => (
    Object.keys(obj).length === 0
    && Object.getPrototypeOf(obj) === Object.prototype
);


export const summy = (array, key) => {
    const sum = array.reduce((accumulator, object) => {
        return accumulator + object[key];
    }, 0);

    return sum
}