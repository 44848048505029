import SignContext from '../SignContext';
import React, { useState } from 'react';


const SignWrapper = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState('signIn');

  const value = React.useMemo(() => ({
    visible, setVisible, type, setType,
  }), [visible, setVisible, type, setType]);

  return (
    <SignContext.Provider value={value}>
      {children}
    </SignContext.Provider>
  );
};

export default SignWrapper;
