import { Card } from 'antd'
import React, { useEffect, useState } from 'react'
import useFetch from '../hooks/useFetch'
import { summy } from '../utils/helper'

const UserCard = ({ user }) => {

    const [sessions, setSessions] = useState([])
    const { startFetch, response } = useFetch()
    const onLogout = () => {
        startFetch({ url: '/logout', method: 'POST', payload: {} })
    }

    useEffect(() => {
        if (response?.ok) {
            window.location.reload()
        }
    }, [response])


    useEffect(() => {
        const getSessions = localStorage.getItem('sessions');
        if (getSessions?.length > 0) {
            setSessions(JSON.parse(getSessions))
        }
        return () => {

        }
    }, [localStorage.getItem('sessions')])


    return (
        <Card title="User">
            <div className='grid grid-flow-row lg:grid-flow-col auto-cols-auto'>
                <div>
                    <p className='font-semibold'>Active User:</p>
                    <p>{user?.email}</p>
                    <p onClick={onLogout} className='text-blue-700 cursor-pointer'>Logout</p>
                </div>
                <div>
                    <p className='font-semibold'>Stats All:</p>
                    <div className='grid grid-flow-col'>
                        <p className='font-semibold'>PnL: <p className='text-green-700'>{summy(sessions, 'totalPnL')}</p></p>
                        <p className='font-semibold'>Buys: <p className='text-green-700'>{summy(sessions, 'totalBuy')}</p></p>
                        <p className='font-semibold'>Sells: <p className='text-red-700'>{summy(sessions, 'totalSell')}</p></p>

                    </div>


                </div>
            </div>

        </Card>
    )
}

export default UserCard