/* eslint-disable no-void */
/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext } from 'react';

const ModalContext = createContext({
    visible: false,
    message: 'Modal',
    btnText: 'Ok!',
    type: 'error',
    setVisible: () => void 0,
    setMessage: () => void 0,
    setBtnText: () => void 0,
    setType: () => void 0,
});

export default ModalContext;

