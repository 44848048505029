import { useContext, useEffect, useState } from "react";
import ModalContext from "../context/modaContext";
import { isEmptyObject } from "../utils/helper";

const useFetch = () => {
  const [response, setResponse] = useState()
  const { setMessage, setBtnText, setType, setVisible } = useContext(ModalContext)
  const startFetch = async ({ url, token = '', method = 'POST', payload }) => {
    try {
      let options = {
        headers: {
          'Content-Type': 'application/json',
          "Accept": '*/*'
        },
        credentials: "include",
        method,
      }
      if (method === 'POST') {
        options = {
          ...options,
          body: JSON.stringify(payload)
        }
      }
      const request = await fetch(`${process.env.REACT_APP_SERVER_URL}${url}`, {
        ...options
      });
      const response = await request.json()
      console.log(`${url}`, response)
      setResponse(response)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (response) {
      if (!isEmptyObject(response)) {
        if (!response?.ok) {
          console.log(response, "????")
          setVisible(true)
          setBtnText('Okay');
          setMessage(response?.message)
          setType('error')
        }
      }

    }

  }, [response])

  return { startFetch, response };
};

export default useFetch;
