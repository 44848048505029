import { useContext, useEffect, useState } from 'react'
import SignContext from '../context/SignContext'
import useFetch from './useFetch'

const useAuth = () => {
    const { startFetch, response } = useFetch()
    const { setVisible } = useContext(SignContext)
    const [user, setUser] = useState()

    useEffect(() => {
        startFetch({ url: '/verify', method: 'GET' })
    }, [])

    useEffect(() => {
        if (response?.ok) {
            setUser(response?.data)
            setVisible(false);
        }
        if (!response?.ok) {
            setVisible(true)
        }
    }, [response])

    return { user }
}

export default useAuth