import ModalContext from '../modaContext';
import React, { useState } from 'react';


const ModalWrapper = ({ children }) => {
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [btnText, setBtnText] = useState('Ok!');
    const [type, setType] = useState('error');

    const value = React.useMemo(() => ({
        visible, message, btnText, type, setVisible, setMessage, setBtnText, setType,
    }), [visible, message, btnText, type,
        setVisible, setMessage, setBtnText, setType]);

    return (
        <ModalContext.Provider value={value}>
            {children}
        </ModalContext.Provider>
    );
};

export default ModalWrapper;