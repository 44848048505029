import { Button, Input, Modal } from 'antd'
import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import SignContext from '../context/SignContext'
import useFetch from '../hooks/useFetch'

const Login = () => {
    const { startFetch, response } = useFetch()
    const { visible } = useContext(SignContext)

    const loginFormik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: (values) => {
            startFetch({
                url: '/login',
                method: 'POST',
                payload: values
            })
        }
    })
    useEffect(() => {
        if (response?.ok) {
            window.location.reload()
        }
    }, [response])

    return (
        <Modal visible={visible} footer={false}>
            <div className="grid grid-flow-row auto-rows-auto gap-2">
                <p className="text-3xl text-center">Sign In</p>
                <p className="text-xl text-center">If you dont have an Account please contact with your referer.</p>

                <Input onChange={loginFormik.handleChange('email')} className="rounded-full" placeholder="Email" />
                <Input.Password onChange={loginFormik.handleChange('password')} className="rounded-full " placeholder="Password" />
                <Button onClick={loginFormik.submitForm} className="bg-gray-700 text-white rounded-full" title="Sign In">Sign In</Button>
                <p className="text-sm text-center">Account fees 0.05 ETH monthly. Please be carefull to not pay more.</p>
            </div>
        </Modal>
    )
}

export default Login